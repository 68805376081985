import React, { useCallback, useEffect, useState } from "react";
import Header from "../../../components/HeaderMobile/Header";
import ThreeBoxes from '../../../components/mobilecomponents/ThreeBoxes';
import Tabs from "../../../components/mobilecomponents/Tabs/Tabs";
import TeamManagerMobileCard from './teamManagerMobileCard';
import styles from './styles.module.scss';
import footerLogo from '../../../assets/images/bitmap.png';
import { useDispatch, useSelector } from "react-redux";
import MyScoreDetailsMobileCard from './myScoreDetailsMobileCard';
import MyScore from "../../../components/mobilecomponents/LiveMatch/MyScore";
import LiveMatch from "../../../components/mobilecomponents/LiveMatch/LiveMatch";
import "../../../components/mobilecomponents/LiveMatch/live_match.scss";
import * as MLBActions from "../../../actions/MLBActions";
import * as NHLActions from "../../../actions/NHLActions";
import { CardType } from "../../../components/SportsLiveCard/CardType";
import moment from "moment";
import { truncate, isEmpty } from "lodash";
import BoosterPopUp from "../../../components/mobilecomponents/BoosterPopUp/BoosterPopUp";
import { getLocalStorage } from "../../../utility/shared";
import * as CryptoJS from "crypto-js";
import { CONSTANTS } from "../../../utility/constants";
import NHLFooterImage from "../../../assets/NHL.png";
import Tick2 from "../../../icons/Tick2";
import _ from "underscore";
import OrangePowerCurrency from "../../../assets/power-orange.png";
import OrangeBtcCurrency from "../../../assets/btc-orange.png";
import OrangeEthCurrency from "../../../assets/ethereum-orange.png";
import CurrencyFormat from "react-currency-format";
import dateFormat from "dateformat";
import ContestRulesPopUp from "../../../components/ContestRulesPopUp";
import RightArrow from "../../../assets/right-arrow.png";
const TeamManagerMobileMLB = (props) => {
  const [state, setState] = useState(1);
  function getTeamFromLocalStorage() {
    const encData = getLocalStorage(CONSTANTS.LOCAL_STORAGE_KEYS.MLB_LIVE_GAME);
    const byteData = CryptoJS.AES.decrypt(encData, CONSTANTS.DATA_ENC_KEY);
    const decSelectedTeamData = JSON.parse(
      byteData.toString(CryptoJS.enc.Utf8)
    );
    return decSelectedTeamData;
  }
  const gameID = localStorage.getItem("mlb_live_game_id");
  const userID = localStorage.getItem("PERSONA_USER_ID");
  const livePlayerss = useSelector(state => state.teamManager);
  const selectedTeam = getTeamFromLocalStorage();
  const {
    livePlayers: live_players = [],
    liveTeamD: live_teamD = {},
    live_clock = "20:00",
    live_team_logs = []
  } = livePlayerss;

  const { challenge_amount,
    currency,
    enrolledUsers,
    entryFees,
    gameStatus,
    gameType,
    id,
    league,
    pointSystem,
    prizeCurrency,
    reward,
    startDate,
    status,
    targetUsers, gameStartTime } = selectedTeam
  const {
    teamLogs = []
  } = live_team_logs;

  const {
    data = [],
    onChangeXp = (xp, player) => { },
    getFantasyTeam = () => { },
    live_totalTeamPts,
    score,
    powers,
    prize_currency = "USD",
    prizePool,
    isMlb=true
  } = props || {};
  const { playersPoints={}}= useSelector((state) => state.teamManager);
  const { liveStandings = [] } = useSelector((state) => state.nhl);
  const { user_id } = useSelector((state) => state.auth.user);
  useEffect(() => {
    let leaderScore = 0;
    if (liveStandings.length > 0) {
      liveStandings.forEach((element, index) => {
        if (element?._id?.userID == user_id) {
          setCurrentRank(element?.rank);
          setCurrentWinnings(element?.prize);
        }

        leaderScore = Math.max(...liveStandings.map(o => o.totalValue));
        setLeader(leaderScore);
      });
    }
  }, [liveStandings]);

  const { 
    positionP = 0,
    positionC = 0,
    positionSS = 0,
    positionXB1 = 0,
    positionXB2 = 0,
    positionOF1 = 0,
    positionOF2 = 0,
    teamDPoints = 0,
    total_points= positionP +
    positionC +
    positionSS +
    positionXB1 +
    positionXB2 +
    positionOF1 +
    positionOF2 +
    teamDPoints 
} = playersPoints;

  const [swap, setSwap] = useState(false);
  const [secondModal, setSecondModal] = useState(false);
  const [rankss, setRanks] = useState({});
  const [liveStandingData, setLiveStandingData] = useState([]);
  const [currentWinnings, setCurrentWinnings] = useState(0);
  const [leader, setLeader] = useState(0);
  const [currentRank, setCurrentRank] = useState(0);
  const [selectedPlayer, setSelectedPlayer] = useState({});
  const [loadingPlayerList, setLoadingPlayerList] = useState(false);
  const [swapPlayerList, setPlayerList] = useState({});
  const [filteredPlayerList, setFilteredPlayerList] = useState({});
  const [showReplaceModal, setReplaceModalState] = useState(false);

  const dispatch = useDispatch();


  const { match = {} } = data || {};
  const { date_time = "", scheduled = "" } = match || {};
  const boostModal = (value, player = {}, type, close) => {
    if (value) {
      setSelectedPlayer(player);
    } else {
      setSelectedPlayer({});
    }
    setSecondModal(close);
  };

  let filteredData = []


  const swapModal = (value, player = {}) => {
    if (value) {
      setSelectedPlayer(player);
    } else {
      setSelectedPlayer({});
    }
  };

  const getPrizeCurrency = (prize_currency) => {
    if (prize_currency.toUpperCase() === "PWRS") {
      return OrangePowerCurrency;
    } else if (prize_currency?.toUpperCase() === "BTC") {
      return OrangeBtcCurrency;
    } else if (prize_currency?.toUpperCase() === "ETH") {
      return OrangeEthCurrency;
    } else if (prize_currency?.toUpperCase() === "USD") {
      return "$"
    }
  };

  const RowSwapPlayer = ({
    position,
    name,
    time,
    plays,
    powers,

    sStamp
  }) => (
    <div className={styles.scoreCard}>
      <div className={styles.topPart}>
        <div className={styles.positions}>
          {position}
        </div>
        <div className={styles.seperator}></div>
        <div className={styles.playerDetails}>
          <p>{name}</p>
        </div>
      </div>
      <div className={styles.middlePart}>
        <div className={styles.statsPlayer + ' statsPlayerDiv'}>
          <div className={styles.statItem}>
            <p>Time Stamp</p>
            <div className={styles.itemBottom}>

              <div className={styles.itemBottomPartTime} style={{ marginTop: "20px" }}>
                <span>{sStamp}</span>
              </div>
            </div>
          </div>

          <div className={styles.statItem}>
            <p>Game Clock</p>
            <div className={styles.itemBottom}>
              <div className={styles.itemBottomPart} style={{ marginTop: "20px" }}>
                <span>{time.split("|")[0]}</span>
              </div>
              <div className={styles.itemBottomPart} style={{ marginTop: "20px" }}>
                <span>{time.split("|")[1]}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bottomPart}>
        <div>
          {plays}
        </div>
      </div>
    </div>
  );

  const getGameTypeText = (game_type) => {
    console.log("inside func => ", challenge_amount)
    if (game_type === "PowerdFS") {
      return "";
    }
    else if (game_type === "PowerdFs_Recharge") {
      return "";
    }
    else if (game_type === "PowerdFs_open") {
      return "";
    }
    else if (game_type === "PowerdFs_promo" || game_type === "Fantasy_Promo") {
      if (props.isTeamSelectionPage) {
        return <><br /> Manager Challenge </>;
      }
      else {
        return "Manager Challenge";
      }
    }
    else if (game_type === "PowerdFs_challenge" || game_type === "Fantasy_Challenge") {
      if (props.isTeamSelectionPage) {
        return <><br /> {challenge_amount + " "} Point Challenge </>;
      }
      else {
        return `${challenge_amount} Point Challenge`;
      }
    }
    else if (game_type === "PowerdFs_Progressive") {
      if (props.isTeamSelectionPage) {
        return <><br /> Progressive Jackpot </>;
      }
      else {
        return "Progressive Jackpot";
      }
    }
    else if (game_type === "PowerdFs_One") {
      return "One";
    }
  }

  const Gameheader = ({ league = "", gameType = "" }) => {
    return (
      <section className={styles.topSectionMLB} style={{ paddingTop: "35px" }}>
        <div className={styles.topTitle}>
          {league} <span>{["Fantasy", "Fantasy_Challenge", "Fantasy_Promo"].indexOf(gameType) !== -1 ? "Fantasy" : "PowerdFS"}</span> <br /><span style={{
            fontSize: 25, color: "#f2f2f2"
          }}>{getGameTypeText(gameType)}</span>
        </div>
        <div className={styles.entries}>
          {targetUsers !== 0 ?
            <>
              Entries : {enrolledUsers} <span>/ {targetUsers}</span>
            </>
            :
            <>
              Entries : {enrolledUsers}
            </>
          }

        </div>
      </section>
    )
  }
  const getSchedualTime=(matchId)=>{
    let schedualtime=""
    if(props?.getAllgames?.length>0){
    const data= props?.getAllgames?.filter((item)=>
      item?.id===matchId
    )
    schedualtime=data[0]?.scheduled
    }
     return schedualtime
}
const getOutcomes=(outcomes)=>{
  if(outcomes){
      if(outcomes?.current_inning_half==="T"){
          if(outcomes?.current_inning==1){
              outcomes.top_or_bot="Top 1st"
          }
          else if(outcomes?.current_inning==2){
              outcomes.top_or_bot="Top 2nd"
          }
          else if(outcomes?.current_inning==3){
              outcomes.top_or_bot="Top 3rd"
          }
          else{
              outcomes.top_or_bot=`Top ${outcomes?.current_inning}th`
          }
      }
      else if(outcomes?.current_inning_half==="B"){
          if(outcomes?.current_inning==1){
              outcomes.top_or_bot="Bot 1st"
          }
          else if(outcomes?.current_inning==2){
              outcomes.top_or_bot="Bot 2nd"
          }
          else if(outcomes?.current_inning==3){
              outcomes.top_or_bot="Bot 3rd"
          }
          else{
              outcomes.top_or_bot=`Bot ${outcomes?.current_inning}th`
          }
      }
  }
  return outcomes
}
const findStarPlayer=(playerid)=>{
  let startData=false;
props.fantasyPlayers.length>0 && props.fantasyPlayers?.map((item)=>{
    if(item?.id===playerid){
         startData={
            isStarPlayer:item?.isStarPlayer,
            starCategory:item?.starCategory
        }
    }
})
return startData
}
const getBackgroundImageWithStyle = () => {
  let backgroundImageStyle = {
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "inherit",
    backgroundColor: "#17181a",
    backgroundImage: `url(${NHLFooterImage})`,
    backgroundSize: "cover",
    opacity: 0.6,
  };

  // backgroundImageStyle.backgroundPosition = "-16px -13px";

  return backgroundImageStyle;
};
const ContestSummaryRow = ({ text = <></> }) => (
  <div className={styles.column_row}>
    <Tick2 size={17} />
    {text}
  </div>
);
const ContestColumn = ({
  title = "",
  widthClass = {},
  styles = {},
  children = <></>,
}) => (
  <div
    className={`${styles.footer_column} ${widthClass && widthClass}`}
    style={styles}
  >
    <div className={styles.column_title}>
      <p>{title}</p>
    </div>
    {children}
  </div>
);
  return (
    <div className={styles.NHLLivemobile + " " + "nhlmobilelive"}>
      <Header cardType={'mlb'} />
      <Gameheader league={league} gameType={gameType} />
      <ThreeBoxes showTime={true} gameID={gameID} league={league} isMlb={isMlb}/>
      <Tabs state={state} setState={setState} />
      {state === 1 && <section className='cardsSection'>
        {live_players && live_players.map((item, index) => {
          if(item?.type == 'td' || index == 7)
          {
              var data = {                    
                  fantasyPlayerPosition: 'TeamD',
                  full_name: item?.teamD?.name,
                  gameID: item?.gameId,
                  isFromAwayTeam: item?.isFromAwayTeam,
                  isFromHomeTeam: item?.isFromHomeTeam,
                  positionID: item?.positionID,
                  userID: item?.teamD?.userID,
                  match: {
                      away: item?.match?.away,
                      home: item?.match?.home,
                      status: item?.match?.status,
                      id: item?.teamD?.id,
                      matchId:item?.teamD?.matchId,
                      scheduled: getSchedualTime(item?.teamD?.matchId),
                      outcomes:getOutcomes(item?.match?.outcome)
                  }, 
                  id: item.id,
                  isStarPlayer: false,
                  playerId: ""
              }
          }
          else {
              var data = {
                  fantasyPlayerPosition: item?.fantasyPosition,
                  full_name: item?.full_name,
                  gameID: item?.gameId,
                  isFromAwayTeam: item?.isFromAwayTeam,
                  isFromHomeTeam: item?.isFromHomeTeam,
                  positionID: item?.position,
                  userID: item.userId,
                  match: {
                      away: item?.match?.away,
                      home: item?.match?.home,
                      status: item?.match?.status,
                      id: item?.matchId,
                      scheduled: getSchedualTime(item?.matchId),
                      outcomes:getOutcomes(item?.match?.outcome)
                  }, 
                  id: item?.id,
                  isStarPlayer: findStarPlayer(item.playerId)?.isStarPlayer,
                  isStartCatagory:findStarPlayer(item.playerId)?.starCategory,
                  playerId: item?.playerId
              }
          }
          return <TeamManagerMobileCard
            powersAvailables={powers}
            getFantasyTeam={getFantasyTeam}
            updateReduxState={() => { }}
            showReplaceModal={showReplaceModal}
            setReplaceModalState={setReplaceModalState}
            toggleReplaceModal={() => { }}
            loadingPlayerList={loadingPlayerList}
            selectedTeam={selectedTeam}
            starPlayerCount={0}
            playerList={swapPlayerList}
            setSecondModal={setSecondModal}
            playerData={item}
            playerIndex={index}
            type=''
            data={data}
            fantasyPlayers={props.fantasyPlayers} fantasyTeam={props.fantasyTeam}
          />;
        })}
        {Object.keys(live_teamD).length !== 0 && live_teamD.constructor === Object && [live_teamD].map((item, index) => {
          index = 7;
          var data = {                    
            fantasyPlayerPosition: 'TeamD',
            full_name: item?.teamD?.name,
            gameID: item?.gameId,
            isFromAwayTeam: item?.isFromAwayTeam,
            isFromHomeTeam: item?.isFromHomeTeam,
            positionID: item?.positionID,
            userID: item?.teamD?.userID,
            match: {
                away: item?.match?.away,
                home: item?.match?.home,
                status: item?.match?.status,
                id: item?.teamD?.id,
                matchId:item?.teamD?.matchId,
                scheduled: getSchedualTime(item?.teamD?.matchId),
                outcomes:getOutcomes(item?.match?.outcome)
            }, 
            id: item.id,
            isStarPlayer: false,
            playerId: ""
        }
          return <TeamManagerMobileCard
            powersAvailables={powers}
            getFantasyTeam={getFantasyTeam}
            updateReduxState={() => { }}
            showReplaceModal={showReplaceModal}
            setReplaceModalState={setReplaceModalState}
            toggleReplaceModal={() => { }}
            loadingPlayerList={loadingPlayerList}
            selectedTeam={selectedTeam}
            starPlayerCount={0}
            playerList={swapPlayerList}
            setSecondModal={setSecondModal}
            playerData={item}
            playerIndex={index}
            type=''
            data={data}
            fantasyPlayers={props.fantasyPlayers} fantasyTeam={props.fantasyTeam}
          />;
        })
          
        }
      </section>}

      {state === 2 && <section className='scoreBoardSection'>
        {teamLogs.length == 0 && <h2 style={{
          textAlign: "center",
          marginBottom: 50
        }}>No Logs Yet</h2>}
        {teamLogs && teamLogs?.map((item, index1) => {
          if (!(item?.fantasyPosition == "TeamD" && item?.fantasyLog?.type !== "shotagainst")) {
            let itemData = filteredData?.filter((data) => data?.eventID == item?.eventID && data?.fantasyPosition == item?.fantasyPosition)
            if (itemData?.length == 0) {
              filteredData.push(item)
            }
          }
        })}
        {filteredData?.length > 0 && filteredData.sort(function (a, b) { return new Date(a.updated_at) - new Date(b.updated_at) })?.map((item, index) => {
          if (!(item?.fantasyPosition == "TeamD" && item?.fantasyLog?.type != "shotagainst")) {
            if (index === 0) filteredData[index].runningTotal = (item.fantasyPosition == "TeamD") ? item?.fantasyLog?.homeTeamD : item?.fantasyLog?.playerPts;
            else {
              filteredData[index].runningTotal =
                (filteredData[index - 1]?.runningTotal) + ((item.fantasyPosition == "TeamD") ? item?.fantasyLog?.homeTeamD : item?.fantasyLog?.playerPts);
            }
            return <MyScoreDetailsMobileCard
              data={item}
              index={index}
              runningTotal={
                filteredData[index].runningTotal
              }
            />
          }
        })}
      </section>}
      <section className={styles.footer}>
        <img src={footerLogo} />
      </section>
      <section className={styles.contestSection}>
      <>
                <div className={styles.container_footer}>
                  <div className={styles.container_footer_header}>
                    <div className={styles.container_footer_title}>
                      <h2>Contest Rules</h2>
                      <span className={styles.separator} />
                    </div>
                  </div>

                  <div className={styles.__mobilefooter}>
                    <div
                      style={getBackgroundImageWithStyle()}
                      className={styles.__mobilefooterimage}
                    ></div>

                    <ContestColumn title="" widthClass={styles.width_200}>
                      <div className={styles.column_body}>
                        <ContestSummaryRow
                          text={
                            <p>
                              <span style={{display:"flex"}}>
                                {currency ?
                                  currency === 'PWRS' ?
                                    prize_currency === 'USD' ? "$" : (
                                      <img
                                        style={{ marginRight: 4 }}
                                        src={getPrizeCurrency(prize_currency)}
                                        height="20px"
                                        alt=""
                                      />
                                    ) : currency === 'USD' ? "$" : (
                                      <img
                                        style={{ marginRight: 4 }}
                                        src={getPrizeCurrency(currency)}
                                        height="20px"
                                        alt=""
                                      />
                                    ) : "$"
                                  }
                                <CurrencyFormat
                                  value={parseFloat(
                                    _.max(reward, function (ele) {
                                        return ele.amount;
                                    }).amount
                                )}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  // prefix={"$"}
                                  renderText={(value) => <div>{value}</div>}
                                />
                              </span>{" "}
                              Prize Pool
                            </p>
                          }
                        />
                        {!gameType.toLowerCase().includes("fantasy") &&
                          <ContestSummaryRow
                            text={
                              <p>
                                Live-play <span>Powers</span> included with <br/>
                                entry fee
                              </p>
                            }
                          />
                        }
                        <ContestSummaryRow
                          text={
                            <p>
                              Pick players from any teams scheduled to play on{" "}
                              <span>
                                {dateFormat(gameStartTime, "mmmm dS, yyyy")}
                              </span>
                            </p>
                          }
                        />
                      </div>
                    </ContestColumn>

                    <div className={styles.__see_full_rules}>
                      <ContestRulesPopUp
                        game_type={gameType}
                        points={[]}
                        powers={[]}
                        component={({ showPopUp }) => (
                          <button
                            onClick={showPopUp}
                            className={styles.footer_full_rules}
                            href="#"
                          >
                            See Full Rules <img src={RightArrow} alt="" />
                          </button>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </>
      </section>
      <section className='matchWrapper'>
        <LiveMatch
          isMlb={true}
          live_totalTeamPts={total_points}
          gameType={gameType}
          gameStatus={gameStatus}
          gameID={gameID}
          swap={swap}
          setSwap={setSwap}
          secondModal={secondModal}
          setSecondModal={setSecondModal}
          boostModal={boostModal}
          swapModal={swapModal}
          ranks={rankss}
          currentWinnings={currentWinnings}
          currentRank={currentRank}
          leader={leader}
          score={score}
          counts={{}}
          onChangeXp={onChangeXp}
          data={data}
          selectedPlayer={selectedPlayer}
          gameInfo={props?.gameInfo}
        />
      </section>
      <BoosterPopUp secondModal={secondModal} boostModal={boostModal} counts={props.counts} onChangeXp={onChangeXp} data={data} selectedPlayer={selectedPlayer} />

    </div>
  );
};
export default TeamManagerMobileMLB;